import { horizontalanimateScroll } from "./horizontalanimateScroll";

const logError = () =>
  console.error(
    `Invalid element or container, are you sure you've provided react ref?`
  );

const getElementPosition = (element) => element.offsetLeft;

export const horizontalscrollTo = ({ amount = 0, containerref = null, duration = 3000 }) => {
 
 

    const container = containerref ? containerref.current : null;


  if (!container) {
    // log error if the reference passed is invalid
    logError();
    return;
  }

  const initialPosition = container.scrollLeft;

  horizontalanimateScroll({
    targetPosition: amount,
    initialPosition,
    duration,
    container: container
  });
};
