import * as React from "react"

import { Link, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import LanguagePicker from "../components/languagepicker"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { useTranslation } from 'react-i18next'
import InstagramIcon from "../images/instagram_icon.svg"
import DribbleIcon from "../images/dribbble_icon.svg"
import TwitterIcon from "../images/twitter_icon.svg"
import { motion, useTransform, useSpring, useScroll } from "framer-motion"
import Canvas from "../components/canvas"
import Ideascarousel from "../components/ideascarousel"
import { TransitionLink } from "gatsby-plugin-transition-link/components/TransitionLink"
import i18next from "i18next"
import ContactCircleDynamic from "../components/contactcircle"
import { localizeString } from "../components/utils/localization"
import { DesktopOnlyMotion, DesktopOnlyMotionUl } from "../components/utils/motionHelper"


//ANIMATION OPTIONS
const easetransition = {duration: 0.6, ease:[0.43, 0.13, 0.23, 0.96]}; //This is for img hover zoom
const scrolltransition = {duration: 1, ease:[0.215, 0.61, 0.355, 1]}; //This is for snappy scrolling cubic-bezier(.13,.68,.51,.89)

//Variants
const textSlideIn = {
  visible: { opacity: 1, translateX: 0 },
  hidden: { opacity: 0, translateX: "-50vw" },
}

const titleSlideIn = {
  visible: { opacity: 0.3, translateX: 0},
  hidden: { opacity: 0, translateX: "-50vw" },
}

const imgAppear = {
  visible: { opacity: 1, scaleX: 1 },
  hidden: { opacity: 0, scaleX: 0 },
}


const list = {
  visible: { translateY: 0, opacity: 1 },
  hidden: { translateY: "200px", opacity: 0 },
}

const projectslist = {
  visible: { opacity: 1, transition: {ease: [0.215, 0.61, 0.355, 1], duration: 0.5, staggerChildren:0.1, delayChildren:0.5} },
  hidden: {  opacity: 0 },
}
const projectslistitem = {
  visible: { x: 0, opacity: 1, transition: {ease: [0.215, 0.61, 0.355, 1], duration: 0.5} },
  hidden: { x: -90, opacity: 0 },
}




export default function IndexPage({data}) {
  const isGerman= i18next.language == 'de'? true : false;

  const ideanodes = data.ideas.nodes;
 
 
  const { t, i18n } = useTranslation();
//Project Image 1 Hover State
const [img1hovered, setimg1Hovered] = React.useState(false);
const toggleimg1Hover = () => setimg1Hovered(!img1hovered);
//Project Image 2 Hover State
const [img2hovered, setimg2Hovered] = React.useState(false);
const toggleimg2Hover = () => setimg2Hovered(!img2hovered);
//Project Image 3 Hover State
const [img3hovered, setimg3Hovered] = React.useState(false);
const toggleimg3Hover = () => setimg3Hovered(!img3hovered);


//ScrollMotion Value
const [isDark, setDark] = React.useState(false);
const [isTop, setIsTop] = React.useState(true);
const [useCanvas, setUseCanvas] = React.useState(false);
const [isMobile, setisMobile] = React.useState(false);
const { scrollYProgress } = useScroll();


//Canvas
const canvasAlpha = useTransform(scrollYProgress, [0,0.075, 0.15], [1,1, 0]);
//Heading Text Animation - Sizes:
const yPosSize = useSpring(useTransform(scrollYProgress,[0,0.075,0.225], [1,1,5]), { stiffness: 400, damping: 90 })
const yPosSize2 = useSpring(useTransform(scrollYProgress,[0,0.075,0.225], [1,1,0]), { stiffness: 400, damping: 90 })
const yPosSize3 = useSpring(useTransform(scrollYProgress,[0,0.075,0.225], [1,1,5]), { stiffness: 400, damping: 90 })
//Text 1 - Horizontal gets transformed into percentage
const yVertical1 = useSpring(useTransform(scrollYProgress,[0,0.075,0.225], [0,1,-500]), { stiffness: 400, damping: 90 })
const yHorizontal1 = useSpring(useTransform(scrollYProgress,[0,0.05], [-20,0]), { stiffness: 400, damping: 90 })
const percentyHorizontal1 = useTransform(yHorizontal1, (value) => `${value}%`)
//Text 2 - Horizontal gets transformed into percentage
const yHorizontal2 = useSpring(useTransform(scrollYProgress,[0,0.05], [35,0]), { stiffness: 400, damping: 90 })
const percentyHorizontal2 = useTransform(yHorizontal2, (value) => `${value}%`)
//Text 3 - Horizontal gets transformed into percentage
const yVertical3 = useSpring(useTransform(scrollYProgress,[0,0.075,0.225], [-10,1,500]), { stiffness: 400, damping: 90 })
const yHorizontal3 = useSpring(useTransform(scrollYProgress,[0,0.05], [-20,0]), { stiffness: 400, damping: 90 })
const percentyHorizontal3 = useTransform(yHorizontal3, (value) => `${value}%`)
const yPosOpacityLate = useTransform(scrollYProgress, [0.225, 0.23], [1,0]);
//Second Heading Text
const ySecondText = useTransform(scrollYProgress, [0, 0.375,0.475,0.5], [0,0,-900,-2000]);
const [secondTextAnim, setSecondTextAnim] = React.useState(0);
//Buttons
const yTurnOffButtons = useTransform(scrollYProgress,[0,0.019,0.02], ["auto","auto","none"])
const yPosOpacityFast = useTransform(scrollYProgress, [0, 0.075,0.1], [1,1, 0])
//LanguagePicker Animation
const langY = useTransform(scrollYProgress,[0,0.075,0.2,0.3], [1,1,200,600])
const langX = useTransform(scrollYProgress,[0,0.075,0.2,0.3], [1,1,-200,-600])
const langMobile = useTransform(scrollYProgress,[0,0.02,0.03], [1,1,0])
//Social Media Bar
const socialmediaX = useTransform(scrollYProgress,[0,0.075,0.2,0.3], [1,1,200,600])






React.useEffect(() => scrollYProgress.onChange(latest => {

//SecondTextAnim
setSecondTextAnim(function(){
  if(scrollYProgress.current<0.225 || scrollYProgress>0.375){
   return 0;
  }
  else{
   return ((scrollYProgress.current-0.225)*6.66666);
  }
}
  )


  //Set Page to dark
//Mobile
if(window.innerWidth < 1200)
{
  setDark(false);


}
//Desktop
if(window.innerWidth >= 1200)
{
  if (scrollYProgress.current<0.225)
  {
  
    setIsTop(true);

  }
  else {
    setIsTop(false);
  }
  
  if (scrollYProgress.current>0.225 && scrollYProgress.current<0.725)
  {
  
    setDark(true);

  }
  else if(scrollYProgress.current<=0.225 || scrollYProgress.current>=0.725)
  {
    setDark(false);
  }

}
 

}), []);

function handleResize() {
  if(window.innerWidth >= 1200)
  {
    setisMobile(false);
    
  }
  else{
    setisMobile(true);
      }

  const aspectRatio = window.innerWidth / window.innerHeight;
        if(aspectRatio < 0.85 || window.innerWidth < 1200)
    {
      setUseCanvas(false);

    }
    else {
    setUseCanvas(true);
    }

}

//Init
React.useEffect(() => {



// Add event listener
window.addEventListener("resize", handleResize);
// Call handler right away so state gets updated with initial window size
handleResize();
// Remove event listener on cleanup
return () => window.removeEventListener("resize", handleResize);
}, []) 


//Scroll Progress Indicator
const yRange = useTransform(scrollYProgress, [0, 0.9], [0, 1]);
const pathLength = useSpring(yRange, { stiffness: 400, damping: 90 });





  return (
  
  <Layout type = "home" isDark={isDark} isMobile={isMobile}>
    <section id="welcome-container" className={isMobile? "front" : isTop? "front bg-0":"front"}>
      <motion.div className="canvas-container" initial={{opacity:0}} animate={{opacity:1}} transition= {{ ease: "easeOut", duration: 0.5, delay:1.5 }}>
        <motion.div style={{opacity: canvasAlpha}}>
          {
            useCanvas?
            <Canvas active={useCanvas?true:false}></Canvas>
            :
            <StaticImage className="hero-image" src="../images/front-img.jpg" alt={isGerman? "Eine Hand, die nach einem weißen Vorhang greift" : "A hand reaching out to a white curtain"}></StaticImage>
            }
        </motion.div>
      </motion.div> 
        
      <motion.div  id="inner" className="flex" >
        <div className="heading-container flex">
          <div className="heading-container-col-1"></div>
          <div className="heading-container-col-2 flex"> 
            <h1>
              <div className="mainheading dark" id="heading-first-line"><motion.div initial={{x:-90, opacity:0}} animate={{x:0, opacity:1}} transition= {{ ease: [0.65, 0, 0.35, 1], duration: 1.1, delay:0 }} ><motion.div style={isMobile? {y:yVertical1, opacity:yPosOpacityFast} : {scale: yPosSize, y:yVertical1, x:percentyHorizontal1, opacity:yPosOpacityLate}}>{t('mainheading')}</motion.div></motion.div></div>
              <div className="mainheading dark" id="heading-second-line"><motion.div initial={{x:90, opacity:0}} animate={{x:0, opacity:1}} transition= {{ ease: [0.65, 0, 0.35, 1], duration: 1.1, delay:0.1 }}><motion.div style={isMobile? {y:yVertical1, opacity:yPosOpacityFast} : {scale: yPosSize2, x:percentyHorizontal2, opacity:yPosOpacityLate}} >{t('mainheading-line2')}</motion.div></motion.div></div>
              <div className="mainheading dark" id="heading-third-line"><motion.div initial={{x:-90, opacity:0}} animate={{x:0, opacity:1}} transition= {{ ease: [0.65, 0, 0.35, 1], duration: 1.1, delay:0.1 }}><motion.div style={isMobile? {y:yVertical1, opacity:yPosOpacityFast} : {scale: yPosSize3, y:yVertical3, x:percentyHorizontal3, opacity:yPosOpacityLate}} >{t('mainheadingitalic')}</motion.div></motion.div></div>
            </h1>
          </div>
          <div className="heading-container-col-3 flex">
            <motion.ul initial={{x:50, opacity:0}} animate={{x:0, opacity:1}} transition= {{ ease: "easeOut", duration: 1, delay:1 }} className="social-media-list" style={{x:socialmediaX}}>
              <li><a href ="https://www.instagram.com/byvanillanoir" target="_blank" rel="noopener noreferrer"> <img height="20px" src={InstagramIcon} alt="Instagram icon"/></a></li>
              <li><a href ="https://twitter.com/ByVanillaNoir" target="_blank" rel="noopener noreferrer"> <img height="20px" src={TwitterIcon} alt="Twitter icon"/></a></li>
              <li><a href ="https://dribbble.com/vanillanoir" target="_blank" rel="noopener noreferrer"> <img height="20px" src={DribbleIcon} alt="Dribble icon"/></a></li>
            </motion.ul>
          </div>
        </div>
        <motion.div className="second-heading-container" style={{y:ySecondText}}>
          <h2 >{t('mainsecondheading').split("").map((char, index, arr) => {
            let percentage = index/arr.length;
            let testbool = false;
            if(secondTextAnim>percentage){testbool = true}
            let test = testbool? 1 : 0;
            return (
              <motion.span className="light" style={{opacity: test}} key={char+index}>{char}</motion.span>
            )}
            )}
          </h2> 
        </motion.div>    
      </motion.div>

      <motion.div style={{opacity: yPosOpacityFast, pointerEvents:yTurnOffButtons}} className="cta-button-container">
        <motion.div initial={{opacity:0}} animate={{opacity:1}} transition= {{ ease: "easeOut", duration: 0.6, delay:1.5 }} >
          <TransitionLink exit={{length:1}} entry={{delay: 1}} to={localizeString("/contact")}><ContactCircleDynamic className="cta-rotating"/></TransitionLink>
        </motion.div>
      </motion.div>
    </section>

    <section>
      <motion.div initial={{y:50, opacity:0}} animate={{y:0, opacity:1}} transition= {{ ease: "easeOut", duration: 1.5, delay:1 }} className="lang-picker">
          <motion.div style={isMobile?{opacity:langMobile}:{y:langY, x:langX}}><LanguagePicker isdark={isDark}></LanguagePicker></motion.div>
        </motion.div>  
      <motion.div initial={{opacity:0}} animate={{opacity:1}} transition= {{ ease: "easeOut", duration: 1.5, delay:1 }}>
        <div className={isDark? "progress-indicator light" : "progress-indicator dark"}>
          <span className="scroll">scroll</span>
          <div className="progress flex">
            <svg className="progress-icon" viewBox="0 0 60 60">
              <motion.path
                fill="none"
                strokeWidth="10"
                stroke="#363636"
                strokeDasharray="0 1"
                d="M 0, 20 a 20, 20 0 1,0 40,0 a 20, 20 0 1,0 -40,0"
                style={{
                  pathLength,
                  rotate: 90,
                  translateX: 5,
                  translateY: 5,
                  scaleX: -1 // Reverse direction of line animation
                }}
              />
            </svg>
          </div>
        </div>
      </motion.div>
      <div className='emptyspace frontpage'></div>
      </section>
               
      {/*initial="hidden" whileInView="visible" transition={scrolltransition}  viewport={{ once: false }} variants={imgAppear} */}
      <DesktopOnlyMotion className="ourwork-div" initial="hidden" whileInView="visible" viewport={{ once: true }} transition={{ ease: "easeOut", duration: 1}} variants={titleSlideIn}>
        <h3 className={isDark? "section-heading light" : "section-heading dark"} id="ourwork">{t('ourwork')}</h3>
      </DesktopOnlyMotion>

    <section id="front-grid">
    <div className="project-container" id="project-1">
        <div className={isDark?"project-item flex light":"project-item flex dark"}>
          <motion.div className="frame" id="frame-1" onMouseEnter={isMobile? null : toggleimg1Hover} onMouseLeave={isMobile? null : toggleimg1Hover} initial={{translateX:"-100%"}} whileInView={{translateX:0}} transition={scrolltransition}  viewport={{ once: false }}  style={{}}>
            <Link to={localizeString("/projects/balancey")}>
              <motion.div className="frame-animator" initial={{translateX:"100%"}} whileInView={{translateX:0}} transition={scrolltransition}  viewport={{ once: false }}>
                  <div  className={img1hovered? "project-img active" : "project-img fixed"}><StaticImage id="project-img-1a" src="../images/projects/balancey-02.png" alt={isGerman? "Ausgabendiagramm" : "Expenses diagram"} placeholder="blurred"/></div>
                  <div  className={img1hovered? "project-img" : "project-img active"} ><StaticImage id="project-img-1b" src="../images/projects/balancey-01.png" alt={isGerman? "Im Bett sitzende Frau mit Handy" : "Woman sitting in bed with a cell phone"} placeholder="blurred"/></div>
              </motion.div>      
            </Link>
          </motion.div>   
          <div className="project-info flex">
           <DesktopOnlyMotion initial={{y:100,opacity:0}} whileInView={{y:0,opacity:1}} transition={scrolltransition} className="project-title-container"><Link to={localizeString("/projects/balancey")}><h4 className="project-title">Balancey</h4></Link></DesktopOnlyMotion> 
           <DesktopOnlyMotion initial={{y:100, opacity:0}} whileInView={{y:0,opacity:1}} transition={scrolltransition} className="project-subtitle-container"><span className="project-subtitle">{t("balanceydescription")}</span></DesktopOnlyMotion>
           <DesktopOnlyMotion initial={{x:-90,opacity:0}} whileInView={{x:0,opacity:1}} transition={{ease: [0.65, 0, 0.35, 1], duration: 0.6}} className="view-all flex" >
              <div className="view-all-links">
                <Link to={localizeString("/projects/balancey")}><span className={isDark? "light" : "dark"}>{t('readmore')}</span></Link>
                <Link to={localizeString("/projects/balancey")} className={isDark?"light arrow-right-more":"dark arrow-right-more"}><svg height="20" width="58"  alt="right-pointing arrow"><path d="m 0 10 l 56 0 m -6 6 l 6 -6 l -6 -6" stroke={isDark?"#EEEBEB":"#363636"} strokeWidth="1.7" fill="none"></path></svg><span className={isDark?"light":"dark"}></span></Link>
              </div>
            </DesktopOnlyMotion>
          </div>
        </div>
      </div>

      <div className="project-container" id="project-2">
        <div className={isDark?"project-item flex light":"project-item flex dark"}>
          <div className="project-info flex" id="info-2">
           <DesktopOnlyMotion initial={{y:100,opacity:0}} whileInView={{y:0,opacity:1}} transition={scrolltransition} className="project-title-container"><Link to={localizeString("/projects/write-with-laika")}><h4 className="project-title">Write with Laika</h4></Link></DesktopOnlyMotion> 
           <DesktopOnlyMotion initial={{y:100, opacity:0}} whileInView={{y:0,opacity:1}} transition={scrolltransition} className="project-subtitle-container"><span className="project-subtitle">{t("laikadescription")}</span></DesktopOnlyMotion>
           <DesktopOnlyMotion initial={{x:-90,opacity:0}} whileInView={{x:0,opacity:1}} transition={{ease: [0.65, 0, 0.35, 1], duration: 0.6}} className="view-all flex" >
              <div className="view-all-links">
                <Link to={localizeString("/projects/write-with-laika")}><span className={isDark? "light" : "dark"}>{t('readmore')}</span></Link>
                <Link to={localizeString("/projects/write-with-laika")} className={isDark?"light arrow-right-more":"dark arrow-right-more"}><svg height="20" width="58"  alt="right-pointing arrow"><path d="m 0 10 l 56 0 m -6 6 l 6 -6 l -6 -6" stroke={isDark?"#EEEBEB":"#363636"} strokeWidth="1.7" fill="none"></path></svg><span className={isDark?"light":"dark"}></span></Link>
              </div>
            </DesktopOnlyMotion>
          </div>
          <motion.div className="frame" id="frame-1" onMouseEnter={isMobile? null : toggleimg2Hover} onMouseLeave={isMobile? null : toggleimg2Hover} initial={{translateX:"-100%"}} whileInView={{translateX:0}} transition={scrolltransition}  viewport={{ once: false }}  style={{}}>
            <Link to={localizeString("/projects/write-with-laika")}>
              <motion.div className="frame-animator" initial={{translateX:"100%"}} whileInView={{translateX:0}} transition={scrolltransition}  viewport={{ once: false }}>
                  <div  className={img2hovered? "project-img active" : "project-img fixed"}><StaticImage id="project-img-2a" src="../images/projects/laika-4.jpg" alt={isGerman? "Schwarze Schreibmaschine" : "black typewriter" } placeholder="blurred"/></div>
                  <div  className={img2hovered? "project-img" : "project-img active"} ><StaticImage id="project-img-2b" src="../images/projects/laika-1.png" alt={isGerman? "Heißluftballon, der durch rosa Wolken schwebt" : "Hot air balloon floating through pink clouds"} placeholder="blurred"/></div>
              </motion.div>      
            </Link>
          </motion.div>   
        </div>
      </div>

      <div className="project-container" id="project-3">
      <div className={isDark?"project-item flex light":"project-item flex dark"}>
      <motion.div className="frame" id="frame-2" onMouseEnter={isMobile? null : toggleimg3Hover} onMouseLeave={isMobile? null : toggleimg3Hover} initial={{translateX:"-100%"}} whileInView={{translateX:0}} transition={scrolltransition}  viewport={{ once: false }}  style={{}}  >
          <Link to={localizeString("/projects/gdt-new-york")}>
       <motion.div className="frame-animator" initial={{translateX:"100%"}} whileInView={{translateX:0}} transition={scrolltransition}  viewport={{ once: false }} >
          <div className={img3hovered? "project-img active" : "project-img fixed"}><StaticImage style={{position: "absolute"}} id="project-img-3a" src="../images/projects/gdt-1.jpg" alt={isGerman? "modernes Esszimmer mit Meerblick" : "modern dining room with sea view"}/></div>
          <div className={img3hovered? "project-img" : "project-img active"}><StaticImage style={{position: "absolute"}} id="project-img-3b" src="../images/projects/gdt.png" alt={isGerman? "Eine moderne Designerküche" : "a modern designer kitchen"}/></div>
      </motion.div>
          </Link>
          </motion.div>
        <div className="project-info flex" id="info-3">
           <DesktopOnlyMotion initial={{y:100,opacity:0}} whileInView={{y:0,opacity:1}} transition={scrolltransition} className="project-title-container"><Link to={ localizeString("/projects/gdt-new-york") }><h4 className="project-title">Global Design Talks</h4></Link></DesktopOnlyMotion>
           <DesktopOnlyMotion initial={{y:100, opacity:0}} whileInView={{y:0,opacity:1}} transition={scrolltransition} className="project-subtitle-container"><span className="project-subtitle">{t("gdtdescription")}</span></DesktopOnlyMotion> 
           <DesktopOnlyMotion  initial={{x:-90,opacity:0}} whileInView={{x:0,opacity:1}} transition={{ease: [0.65, 0, 0.35, 1], duration: 0.6}} className="view-all flex" >
              <div className="view-all-links">
                <Link to={ localizeString("/projects/gdt-new-york")}><span className={isDark? "light" : "dark"}>{t('readmore')}</span></Link>
                <Link to={ localizeString("/projects/gdt-new-york")} className={isDark?"light arrow-right-more":"dark arrow-right-more"}><svg height="20" width="58"  alt="right-pointing arrow"><path d="m 0 10 l 56 0 m -6 6 l 6 -6 l -6 -6" stroke={isDark?"#EEEBEB":"#363636"} strokeWidth="1.7" fill="none"></path></svg><span className={isDark?"light":"dark"}></span></Link>
              </div>
            </DesktopOnlyMotion>
          </div>
       
        
         
        </div>
      </div>
      

      <DesktopOnlyMotion  initial={{x:-90,opacity:0}} whileInView={{x:0,opacity:1}} transition={{ease: [0.65, 0, 0.35, 1], duration: 0.6}} className="view-all flex" id="view-all-projects" >
        <div className="view-all-links">
          <TransitionLink exit={{length:1}} entry={{delay: 1}} to={localizeString("/projects")}><span className={isDark? "light" : "dark"}>{t('viewallprojects')}</span></TransitionLink>
        </div>
      </DesktopOnlyMotion>   
    </section>

    

    <section id="horizontal-text">
      <hr className="dashed-line" id="dashed-line-1"></hr>
      <div className="horizontal-text-slider" id="text-slider-1">
        <p className="marquee marquee-first marquee-1">
          <span className={isDark? "light" : "dark"}>UX/UI Design • Technology • Brand • UX/UI Design • Technology • Brand •</span>
        </p>
        <p className="marquee marquee-first marquee-2">
          <span className={isDark? "light" : "dark"}>&nbsp;UX/UI Design • Technology • Brand • UX/UI Design • Technology • Brand •</span>
        </p>
      </div>
      <hr className="dashed-line" id="dashed-line-2"></hr>
      <div className="horizontal-text-slider" id="text-slider-2">
      <p className="marquee marquee-second marquee-1">
          <span className={isDark? "light" : "dark"}>Strategy • Experience • Innovation • Strategy • Experience • Innovation •</span>
        </p>
        <p className="marquee marquee-second marquee-2">
          <span className={isDark? "light" : "dark"}>&nbsp;Strategy • Experience • Innovation • Strategy • Experience • Innovation •</span>
        </p>
      </div>
      <hr className="dashed-line" id="dashed-line-3"></hr>
    </section>

    <section id="titlepage-service-section">
    <DesktopOnlyMotion initial="hidden" whileInView="visible" viewport={{ once: true }} transition={{ ease: [0.215, 0.61, 0.355, 1], duration: 1}} variants={titleSlideIn}><h3 className={isDark? "section-heading light" : "section-heading dark"} id="ourservices">{t('ourservices')}</h3></DesktopOnlyMotion>
      <div className="service-container dark">
      <div className="service-container-content flex">
          <DesktopOnlyMotionUl initial="hidden" whileInView="visible" viewport={{ once: true }} transition={{ ease: [0.215, 0.61, 0.355, 1], duration: 0.5 }}  variants={list} className="service-list" id="Strategy" arialabel={t("strategy")} aria-label={t("strategy")}>
            <li className="service-item">{t("userresearch")}</li>
            <li className="service-item">Prototyping</li>
            <li className="service-item">{t("userjourneys")}</li>
            <li className="service-item">{t("validation")}</li>
            <li className="service-item">{t("visualization")}</li>
            </DesktopOnlyMotionUl>   
          <DesktopOnlyMotionUl initial="hidden" whileInView="visible" viewport={{ once: true }} transition={{ ease: [0.215, 0.61, 0.355, 1], duration: 0.5, delay: 0.2 }} variants={list} className="service-list" id="Design" arialabel="Design" aria-label="Design">
            <li className="service-item">Brand Guidelines</li>
            <li className="service-item">Logo Design</li>
            <li className="service-item">UI/UX Design</li>
            <li className="service-item">Animation</li>
            <li className="service-item">{t("responsivedesign")}</li>
          </DesktopOnlyMotionUl>
          <DesktopOnlyMotionUl initial="hidden" whileInView="visible" viewport={{ once: true }} transition={{ ease: [0.215, 0.61, 0.355, 1], duration: 0.5, delay: 0.4}} variants={list} className="service-list" id="Development" arialabel={t("development")} aria-label={t("development")}>
            <li className="service-item">Landing Pages</li>
            <li className="service-item">{t("webapplications")}</li>
            <li className="service-item">e-Commerce</li>
            <li className="service-item">{t("mobileapps")}</li>
            <li className="service-item">{t("frontend")}</li>
          </DesktopOnlyMotionUl>
          <DesktopOnlyMotionUl initial="hidden" whileInView="visible" viewport={{ once: true }} transition={{ ease: [0.215, 0.61, 0.355, 1], duration: 0.5, delay: 0.6 }} variants={list}className="service-list" id="Maintenance" arialabel="Maintenance" aria-label="Maintenance">
            <li className="service-item">Hosting</li>
            <li className="service-item">Technical Support</li>
            <li className="service-item">Content Updates</li>
            <li className="service-item">Security</li>
            <li className="service-item">SEO Optimization</li>
          </DesktopOnlyMotionUl> 
        </div>
        <div className="view-all flex">
          <hr></hr>
          <div className="view-all-links">
            <TransitionLink exit={{length:1}} entry={{delay: 1}} to={localizeString("/services")} ><span className="dark">{t("readmore")}</span></TransitionLink>
            <TransitionLink exit={{length:1}} entry={{delay: 1}} to={localizeString("/services")} className="arrow-right-more flexgrow"><svg height="20" width="58"  alt="right-pointing arrow"><path d="m 0 10 l 56 0 m -6 6 l 6 -6 l -6 -6" stroke="#363636" strokeWidth="1.7" fill="none"></path></svg><span className="dark"></span></TransitionLink>
          </div>
        </div>
      </div>
    </section>

  
    <section id="about-section">
      <DesktopOnlyMotion initial="hidden" whileInView="visible" viewport={{ once: true }} transition={{ ease: "easeOut", duration: 1}} variants={titleSlideIn}><h3 className="section-heading dark" id="aboutus">{t('aboutus')}</h3></DesktopOnlyMotion>
      <div className="about-wrapper flex">
        <div className="about-text-box flex dark">
          <DesktopOnlyMotion initial={{opacity:0, y:100}} whileInView={{opacity:1, y:0}} viewport={{ once: true }} transition={scrolltransition} variants={imgAppear}><p id="aboutus-text">{t('aboutus-text')}</p></DesktopOnlyMotion>
          <div className="view-all flex">
            <div className="view-all-links">
              <TransitionLink exit={{length:1}} entry={{delay: 1}} to={localizeString("/about")}><span className="dark">{t("moreaboutus")}</span></TransitionLink>
              <TransitionLink exit={{length:1}} entry={{delay: 1}} to={localizeString("/about")} className="arrow-right-more" href="#"><svg height="20" width="58"  alt="right-pointing arrow"><path d="m 0 10 l 56 0 m -6 6 l 6 -6 l -6 -6" stroke="#363636" strokeWidth="1.7" fill="none"></path></svg><span className="dark"></span></TransitionLink>
            </div>
          </div>
        </div>
        <motion.div className="about-us-img-container"  initial={{translateX:"100%"}} whileInView={{translateX:0}} transition={scrolltransition}  viewport={{ once: false }}  style={{}} >
        <motion.div className="about-us-img-container-animator" initial={{translateX:"-100%"}} whileInView={{translateX:0}} transition={scrolltransition}  viewport={{ once: false }} >
          <StaticImage src="../images/seal.jpg" alt={isGerman? "Eine Robbe, die lächelnd in die Luft schaut" : "A seal that smiles and looks towards the sky"} className="about-us-img" />
          </motion.div>
        </motion.div>
      </div>
    </section>

 

    <section id="ideas-section">
      <motion.div initial={{opacity:0}} whileInView={{opacity:1}} transition={{ ease: "easeOut", duration: 1}}>
      <DesktopOnlyMotion className="ideas-title-div" initial="hidden" whileInView="visible" viewport={{ once: true }} transition={{ ease: "easeOut", duration: 1}} variants={titleSlideIn}><h3 className="section-heading dark" id="ideas">{t('ideas')}</h3></DesktopOnlyMotion>
    <Ideascarousel ideanodes={ideanodes}/>
    </motion.div>
    </section>
      
 
  </Layout>
  ) 
}


export const query = graphql`
query IdeasQuery($locale: String) {
  ideas: allFile(
    filter: {
      sourceInstanceName: { eq: "blogs"}
      childMdx: { fields: { locale: { eq: $locale }}}
    },
    sort: {fields: childrenMdx___frontmatter___order, order: DESC})
    {
      nodes {
        childMdx{
        id
        frontmatter {
          title
          slug
          date
          thumbALT
          thumb {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        }
      }
    }
  }

`

export const Head = () => (
  <Seo title="Vanilla Noir | Creative Frontend and UX Agency" titleDE="Vanilla Noir | Kreative Frontend- und UX-Agentur in York" 
  description="We are a full-service international agency for web design and frontend development, focusing on creating unique and beautiful user experiences."
  descriptionDE="Wir sind eine internationale full-service Agentur für einzigartiges Web- und UX-Design, sowie Frontend-Development."
  />  )
