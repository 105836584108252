import React, { useRef, useEffect, useCallback } from 'react';
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { horizontalscrollTo } from './utils/horizontalscrollTo';
import { Link } from 'gatsby';
import { t } from 'i18next';
import { localizeString } from './utils/localization';

function isAnyPartOfElementInViewport(el) {

  const rect = el.getBoundingClientRect();
  // DOMRect { x: 8, y: 8, width: 100, height: 100, top: 8, right: 108, bottom: 108, left: 8 }
  const windowHeight = (window.innerHeight || document.documentElement.clientHeight);
  const windowWidth = (window.innerWidth || document.documentElement.clientWidth);

  // http://stackoverflow.com/questions/325933/determine-whether-two-date-ranges-overlap
  const vertInView = (rect.top <= windowHeight) && ((rect.top + rect.height) >= 0);
  const horInView = (rect.left <= windowWidth) && ((rect.left + rect.width) >= 0);

  return (vertInView && horInView);
}



function Ideascarousel ({ideanodes}) {
    const ref = useRef(null);
    const elementref = useRef(null);




    function scrollToNext()
    {
      let elementwidth = elementref.current.clientWidth;
      let offset;
      if (ref.current.scrollLeft%elementwidth < (elementwidth/2))
      {
        offset = ref.current.scrollLeft - (ref.current.scrollLeft%elementwidth);
      }
      else {
        offset = ref.current.scrollLeft + (elementwidth-(ref.current.scrollLeft%elementwidth));
      }

      horizontalscrollTo({amount:offset, containerref:ref, duration:500});
    }
    const init = useCallback(() => {
     
        const slider = ref.current;
        let isDown = false;
        let startX;
        let scrollLeft;
    
        slider.addEventListener('mousedown', (e) => {
          isDown = true;
          slider.classList.add('active');
          slider.classList.add('stopscrollsnap');

          startX = e.pageX - slider.offsetLeft;
          scrollLeft = slider.scrollLeft;
    
        });
    
        slider.addEventListener('mouseleave', () => {
          isDown = false;
          slider.classList.remove('active');
          scrollToNext();
          
        });
    
        slider.addEventListener('mouseup', () => {
          isDown = false;
          slider.classList.remove('active');
          scrollToNext();
        });
    
        slider.addEventListener('mousemove', (e) => {
          if (!isDown) return;
          e.preventDefault();
          const x = e.pageX - slider.offsetLeft;
          const walk = (x - startX) * 1; //scroll-fast
          slider.scrollLeft = scrollLeft - walk;

        });

  

    })

    useEffect(() => {
      init();

      return function cleanup() {


      };
    }, [])

  

    return (
        
        <div>
            <div ref={ref} className="horizontal-scroll">
              
            
                 {ideanodes.map((idea, index) => {
                   if(index > 8)
                   {
                     return;
                   }                  
                   else{
                    return(
                        <div ref={index==0? elementref : null} className="blogpost-item" id={"blogpost-"+(index+1)} key={"bl-"+index}>
                           <Link to={localizeString("/blog/") + idea.childMdx.frontmatter.slug}><GatsbyImage image={getImage(idea.childMdx.frontmatter.thumb)} alt={idea.childMdx.frontmatter.thumbALT} className="portfolio-img"/><div className={index==0?"overlay-first-item":"overlay"}></div>
                            <div className="blog-info-flexbox">
                                <span className="date">{idea.childMdx.frontmatter.date}</span>
                                <h4>{idea.childMdx.frontmatter.title}</h4>
                            </div></Link>
                        </div>

                      )}})}

                <div className="blogpost-item" id="more-posts">
                <div className="view-all flex" id="all-posts">
                    <div className="view-all-links">
                    <Link to={localizeString("/blog/")}>
                    <a><span className="dark">{t("seeallposts")}</span></a>
                    <span className="arrow-right-more"><svg height="20" width="58"  alt="right-pointing arrow"><path d="m 0 10 l 56 0 m -6 6 l 6 -6 l -6 -6" stroke="#363636" strokeWidth="1.7" fill="none"></path></svg><span className="dark"></span></span>
                    </Link>
                  </div>
                </div>
         
                </div>
            </div>
          </div>);

   


  
}



export default Ideascarousel
